import { render, staticRenderFns } from "./InsAboutUs.vue?vue&type=template&id=336ed092&scoped=true&"
import script from "./InsAboutUs.vue?vue&type=script&lang=ts&"
export * from "./InsAboutUs.vue?vue&type=script&lang=ts&"
import style0 from "./InsAboutUs.vue?vue&type=style&index=0&id=336ed092&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336ed092",
  null
  
)

export default component.exports